import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Icon,
  Spin,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  Divider,
  Popconfirm,
  Drawer
} from 'antd'
import moment from 'moment'
import { DeleteOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons'
import plural from 'plural-ru'

import { getUsersList } from '../../../../redux/actions/ClientActions'

import AnimatedTable from '../../../../components/AnimatedTableComponent'
import EmploymentForm from './EmploymentFormComponent'

import {
  openEmploymentForm,
  closeEmploymentForm,
  openEditEmploymentForm,
  getEmploymentList,
  applyEmploymentFilters,
  resetEmploymentFilters,
  deleteEmployment
} from '../../../../redux/actions/EmploymentActions'

import serviceTypes from '../../../../config/serviceTypes.json'
import taskTypes from '../../../../config/taskTypes.json'
import methodicalWorkTypes from '../../../../config/methodicalWorkTypes.json'
import getServiceTypeName from '../../../../utils/getServiceTypeName'

const { RangePicker } = DatePicker
const { Option } = Select

const initialFilters = {
  date_from: null,
  date_to: null,
  categories: [],
  user_ids: [],
  service_types: [],
  task_types: [],
  methodical_work_types: []
}

class TableTab extends PureComponent {
  state = {
    initialFilters: Object.assign({}, initialFilters),
    filters: Object.assign({}, initialFilters)
  }

  componentDidMount() {
    const { user, showForCurrentUserOnly } = this.props

    if (showForCurrentUserOnly) {
      this.setState(
        {
          initialFilters: {
            ...this.state.initialFilters,
            user_ids: [user.id]
          },
          filters: {
            ...this.state.filters,
            user_ids: [user.id]
          }
        },
        () => {
          this.props.applyEmploymentFilters(this.state.initialFilters)
          this.props.getEmploymentList(
            1,
            this.props.employment.sort,
            this.state.initialFilters
          )
        }
      )
    } else {
      this.props.resetEmploymentFilters()
      this.props.getEmploymentList(1, this.props.employment.sort)
    }

    this.props.getUsersList()
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = e => {
    if (
      this.props.employment.isLoadingItems ||
      this.props.employment.isTotalReached
    ) {
      return
    }

    const body = document.body,
      html = document.documentElement

    const wholePageHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )

    const scrollTop = window.scrollY
    const windowHeight = window.innerHeight

    if (scrollTop > wholePageHeight - windowHeight * 1.05) {
      const pageNumber = this.props.employment.pageNumber + 1
      this.props.getEmploymentList(
        pageNumber,
        this.props.employment.sort,
        this.props.employment.filters
      )
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    let sort = null
    if (Object.keys(sorter).length > 0) {
      sort = {
        field: sorter.field,
        type: sorter.order === 'ascend' ? 'asc' : 'desc'
      }
    }
    this.props.getEmploymentList(1, sort, {
      ...this.props.employment.filters
    })
  }

  getCategoryName(category) {
    let name = ''

    switch (category) {
      case 'service':
        name = 'Услуга'
        break
      case 'task':
        name = 'Задача'
        break
      case 'methodical_work':
        name = 'Методическая работа'
        break
      default:
        break
    }

    return name
  }

  getItemLength(length) {
    let hours = Math.floor(length / 60)
    let minutes = length % 60

    hours = hours > 0 ? hours + ' ч ' : ''
    minutes = minutes > 0 ? minutes + ' мин' : ''

    return hours + minutes
  }

  getItemTypeName(category, type) {
    let name = ''

    switch (category) {
      case 'service':
        name = getServiceTypeName(type)
        break
      case 'task':
        name = this.getTaskTypeName(type)
        break
      case 'methodical_work':
        name = this.getMethodicalWorkTypeName(type)
        break
      default:
        break
    }

    return name
  }

  getTaskTypeName(type) {
    let taskType = taskTypes.find(item => item.key === type)

    return taskType ? taskType.name : 'тип не указан'
  }

  getMethodicalWorkTypeName(type) {
    let methodicalWorkType = methodicalWorkTypes.find(item => item.key === type)

    return methodicalWorkType ? methodicalWorkType.name : 'тип не указан'
  }

  getFormattedCounter() {
    const { employment } = this.props

    let hours = Math.floor(employment.counter.minutes / 60)
    let minutes = employment.counter.minutes % 60

    return (
      employment.counter.items.toLocaleString('ru-RU') +
      ' ' +
      plural(employment.counter.items, 'запись', 'записи', 'записей') +
      ' на ' +
      hours.toLocaleString('ru-RU') +
      ' ' +
      plural(hours, 'час', 'часа', 'часов') +
      ' ' +
      minutes +
      ' ' +
      plural(minutes, 'минута', 'минуты', 'минут')
    )
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const commonData = this.props.client.commonData
    const { employment } = this.props
    const { filters } = this.state

    let tableStyle = null
    if (employment.isLoadingItems && employment.pageNumber === 1) {
      tableStyle = { display: 'none' }
    }

    const onCellActions = (record, rowIndex) => ({
      onClick: event => {
        if (!this.props.disableEditing && record.category_code !== 'service') {
          this.props.openEditEmploymentForm(record.item)
        }
      }
    })

    let columns = []

    if (!this.props.disableEmployeeColumn) {
      columns.push({
        title: 'Специалист',
        dataIndex: 'user_id',
        sorter: true,
        className: 'Clients__table-cell',
        width: 175,
        onCell: onCellActions
      })
    }

    columns = [
      ...columns,
      {
        title: 'Дата',
        dataIndex: 'date',
        sorter: true,
        className: 'Clients__table-cell',
        width: 150,
        onCell: onCellActions
      },
      {
        title: 'Время',
        dataIndex: 'length',
        sorter: true,
        className: 'Clients__table-cell',
        width: 115,
        onCell: onCellActions,
        render: (text, record) => (
          <span
            dangerouslySetInnerHTML={{ __html: text.replaceAll(' ', '&nbsp;') }}
          ></span>
        )
      },
      {
        title: 'Категория',
        dataIndex: 'category',
        sorter: true,
        className: 'Clients__table-cell',
        width: 150,
        onCell: onCellActions
      },
      {
        title: 'Тип',
        dataIndex: 'type',
        className: 'Clients__table-cell',
        width: 250,
        onCell: onCellActions
      },
      {
        title: 'Комментарий',
        dataIndex: 'comment',
        className: 'Clients__table-cell',
        onCell: onCellActions
      }
    ]

    if (!this.props.disableEditing) {
      columns.push({
        title: '',
        key: 'action',
        width: 125,
        render: (text, record) =>
          record.category_code !== 'service' ? (
            <span className="Clients__control-buttons">
              <a
                href="/"
                onClick={e => {
                  e.preventDefault()
                  this.props.openEditEmploymentForm(record.item)
                }}
              >
                <EditOutlined />
              </a>
              <Divider type="vertical" />
              <a
                href="/"
                onClick={e => {
                  e.preventDefault()
                  this.props.openEditEmploymentForm(record.item, true)
                }}
              >
                <CopyOutlined />
              </a>
              <Divider type="vertical" />
              <Popconfirm
                title="Вы уверены, что хотите удалить запись?"
                onConfirm={e => {
                  this.props.deleteEmployment(record.id)
                }}
                okText="Да"
                cancelText="Нет"
              >
                <a href="/">
                  <DeleteOutlined />
                </a>
              </Popconfirm>
            </span>
          ) : null
      })
    }

    const data = employment.items.map(item => ({
      key: item.id,
      id: item.id,
      date: moment(item.date).format('DD.MM.YYYY'),
      length: this.getItemLength(item.length),
      user_id: item.user.user_surname + ' ' + item.user.user_name,
      category: this.getCategoryName(item.category),
      category_code: item.category,
      type: this.getItemTypeName(item.category, item.type),
      comment: item.comment,
      item
    }))

    return (
      <>
        <Row gutter={16}>
          <Col span={8} className="Clients__settings-col">
            <RangePicker
              placeholder={['Дата c', 'Дата по']}
              format={'DD.MM.YYYY'}
              style={{ width: '100%' }}
              onChange={(value, dateString) => {
                this.setState({
                  filters: {
                    ...filters,
                    date_from: dateString[0]
                      ? moment(dateString[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
                      : null,
                    date_to: dateString[1]
                      ? moment(dateString[1], 'DD.MM.YYYY').format('YYYY-MM-DD')
                      : null
                  }
                })
              }}
              value={[
                filters.date_from
                  ? moment(filters.date_from, 'YYYY-MM-DD')
                  : null,
                filters.date_to ? moment(filters.date_to, 'YYYY-MM-DD') : null
              ]}
            />
          </Col>

          {!this.props.disableUserFilter && (
            <Col span={8} className="Clients__settings-col">
              <Select
                placeholder="Выберите сотрудника"
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                value={filters.user_ids}
                onChange={value => {
                  this.setState({
                    filters: {
                      ...filters,
                      user_ids: value
                    }
                  })
                }}
              >
                {commonData.users_list.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.surname} {item.name}
                  </Option>
                ))}
              </Select>
            </Col>
          )}

          <Col span={8} className="Clients__settings-col">
            <Select
              placeholder="Выберите категорию"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={filters.categories}
              onChange={value => {
                this.setState({
                  filters: {
                    ...filters,
                    categories: value
                  }
                })
              }}
            >
              <Option value={'service'}>Услуга</Option>
              <Option value={'task'}>Задача</Option>
              <Option value={'methodical_work'}>Методическая работа</Option>
            </Select>
          </Col>

          <Col span={8} className="Clients__settings-col">
            <Select
              placeholder="Выберите тип услуги"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={filters.service_types}
              onChange={value => {
                this.setState({
                  filters: {
                    ...filters,
                    service_types: value
                  }
                })
              }}
            >
              {serviceTypes.map(item => (
                <Option key={item.key} value={item.key}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={8} className="Clients__settings-col">
            <Select
              placeholder="Выберите тип задачи"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={filters.task_types}
              onChange={value => {
                this.setState({
                  filters: {
                    ...filters,
                    task_types: value
                  }
                })
              }}
            >
              {taskTypes.map(item => (
                <Option key={item.key} value={item.key}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={8} className="Clients__settings-col">
            <Select
              placeholder="Выберите тип методической работы"
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              value={filters.methodical_work_types}
              onChange={value => {
                this.setState({
                  filters: {
                    ...filters,
                    methodical_work_types: value
                  }
                })
              }}
            >
              {methodicalWorkTypes.map(item => (
                <Option key={item.key} value={item.key}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row gutter={16} type="flex" align="middle">
          <Col span={8}>
            <b>Найдено:</b> {this.getFormattedCounter()}
          </Col>
          <Col span={8} offset={8} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              onClick={() => {
                this.props.applyEmploymentFilters(filters)
                this.props.getEmploymentList(1, employment.sort, filters)
              }}
            >
              Применить
            </Button>{' '}
            <Button
              type="dashed"
              onClick={() => {
                this.setState({
                  filters: Object.assign({}, this.state.initialFilters)
                })
                this.props.resetEmploymentFilters()
                this.props.getEmploymentList(
                  1,
                  employment.sort,
                  this.state.initialFilters
                )
              }}
            >
              Сбросить
            </Button>
          </Col>
        </Row>

        <p></p>

        {!this.props.disableEditing && (
          <>
            <Button type="primary" onClick={this.props.openEmploymentForm}>
              Добавить запись
            </Button>

            <p></p>
          </>
        )}

        <AnimatedTable
          columns={columns}
          dataSource={data}
          pagination={false}
          onChange={this.handleTableChange}
          style={tableStyle}
          animation={employment.animation}
        />

        {employment.isLoadingItems && (
          <div className="Clients__loader">
            <Spin indicator={antIcon} />
          </div>
        )}

        <Drawer
          title={!employment.employmentForm.id ? 'Создание' : 'Редактирование'}
          width={'700'}
          onClose={this.props.closeEmploymentForm}
          visible={employment.employmentForm.isOpen}
        >
          <EmploymentForm />
        </Drawer>
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    client: store.client,
    employment: store.employment
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUsersList: () => dispatch(getUsersList()),
    openEmploymentForm: () => dispatch(openEmploymentForm()),
    closeEmploymentForm: () => dispatch(closeEmploymentForm()),
    openEditEmploymentForm: (item, copy = false) =>
      dispatch(openEditEmploymentForm(item, copy)),
    getEmploymentList: (pageNumber = 1, sort = null, filterForm = null) =>
      dispatch(getEmploymentList(pageNumber, sort, filterForm)),
    applyEmploymentFilters: filters =>
      dispatch(applyEmploymentFilters(filters)),
    resetEmploymentFilters: () => dispatch(resetEmploymentFilters()),
    deleteEmployment: id => dispatch(deleteEmployment(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableTab)
